import { APP_ID, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArrayShuffleService {

  constructor() { }

  shuffle(array: any[], seed: number) {
    let copiedArray = this.copy(array);
    var m = copiedArray.length;
    var t: any;
    var i: number;

    while (m) {
      i = Math.floor(this.random(seed) * m--);
      t = copiedArray[m];
      copiedArray[m] = copiedArray[i];
      copiedArray[i] = t;
      ++seed
    }

    return copiedArray;
  }

  private random(seed: number) {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  private copy(array: any[]) {
    return array.slice();
  }
}
