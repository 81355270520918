<div class="center">
    <div [ngStyle]="{'color': color, 'font-size': size + 'px', 'font-weight': 'bold'}">
        <ng-marquee speed="normal">
            marquee
        </ng-marquee>
    </div>

    <div class="color-input">
        <h1>Type in Color:</h1>
        <input [(ngModel)]="color" />
    </div>

    <div class="buttons">
        <h1>Change Size:</h1>
        <button (click)="sizeUp()">+</button>
        <button (click)="sizeDown()">-</button>
    </div>

    <div class="buttons">
        <h1>Change Speed:</h1>
        <button (click)="speedUp()">+</button>
        <button (click)="speedDown()">-</button>
    </div>
</div>