import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MarqueeComponent } from './marquee/marquee.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { SwitchboardComponent } from './switchboard/switchboard.component';
import { FormsModule } from '@angular/forms';
import { NgMarqueeModule } from 'ng-marquee';
import { OverviewComponent } from './overview/overview.component';
import { DeviceComponent } from './device/device.component';

@NgModule({
  declarations: [
    AppComponent,
    MarqueeComponent,
    HomeComponent,
    SwitchboardComponent,
    OverviewComponent,
    DeviceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgMarqueeModule,
    RouterModule.forChild([
      { path: '', component: HomeComponent },
      { path: 'overview', component: OverviewComponent },
      { path: ':id', component: DeviceComponent }
    ]),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
