import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switchboard',
  templateUrl: './switchboard.component.html',
  styleUrls: ['./switchboard.component.scss']
})
export class SwitchboardComponent implements OnInit {

size: number = 50;
speed: number = 50;
color: string; 

  constructor() { }

  ngOnInit(): void {
  }

  sizeUp(){
    this.size = this.size + 1;
  }

  sizeDown(){
    this.size = this.size - 1;
  }

  speedUp(){
    this.speed = this.speed + 1;
  }

  speedDown(){
    this.speed = this.speed - 1;
  }

}
