import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeCalculationService {

  constructor() { }

  // calculate difference to next quarter-hour
  msToNextQuarterHour() {
    const oneQuarterHourInMs = 1000 * 60 * 15;
    return oneQuarterHourInMs - new Date().getTime() % oneQuarterHourInMs;
  }
}
