import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TextService } from '../services/text.service';
import { TimeCalculationService } from '../services/time-calculation.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrls: ['./marquee.component.scss']
})
export class MarqueeComponent implements OnInit, OnDestroy {
  @Input() deviceId: number = 1;
  text: string;
  subscription: Subscription;
  changeTextAfterSeconds = 900;
  index: number = 0;
  localStorageKey: string;
  avgNumberOfCharactersPerText: number = 42; // default value
  duration: number = 20;
  durationPerCharacter: number = this.duration / this.avgNumberOfCharactersPerText;

  constructor(private textService: TextService, private timeCalculation: TimeCalculationService) { }

  ngOnInit(): void {
    this.localStorageKey = "index_device" + this.deviceId;
    const indexFromLocalStorage = parseInt(localStorage.getItem(this.localStorageKey));

    if (!isNaN(indexFromLocalStorage)) {
      this.index = indexFromLocalStorage;
    }

    this.getTextByIndex(this.index);
    this.setUp();
    this.calculateAvgNumberOfCharsPerText();
  }

  setUp() {
    // start an interval of duration to the next quarter-hour
    setTimeout(() => {
      this.changeText();
      this.subscription = interval(this.changeTextAfterSeconds * 1000).subscribe(val => {
        this.changeText();
      });
    }, this.timeCalculation.msToNextQuarterHour());
  }

  changeText() {
    this.getTextByIndex(++this.index);
    localStorage.setItem(this.localStorageKey, this.index.toString());
  }

  getTextByIndex(index: number) {
    this.textService.getTextByDeviceIdAndIndex(this.deviceId, index).subscribe(
      text => {
        this.text = text;
        this.duration = this.durationPerCharacter * text.length;
        console.log(`${this.deviceId}: ${this.duration}`);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  calculateAvgNumberOfCharsPerText() {
    this.textService.getAllTexts().subscribe(
      texts => {
        let sum = 0;
        for (var i = 0; i < texts.length; i++) {
          sum = sum + texts[i].length;
        }
        this.avgNumberOfCharactersPerText = sum / texts.length;
      }
    );
  }
}
