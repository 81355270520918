import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { ArrayShuffleService } from './array-shuffle.service';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  private api = '../assets/texts.json'

  constructor(private http: HttpClient, private arrayShuffleService: ArrayShuffleService) { }

  getAllTexts(): Observable<string[]> {
    return this.http.get<any>(this.api)
      .pipe(
        map((text: any) => {
            return [...text.device1, ...text.device2, ...text.device3, ...text.device4];
        })
      );
  }

  getTextByDeviceIdAndIndex(deviceId: number, index: number): Observable<string> {
    return this.http.get<any>(this.api)
      .pipe(
        map((text: any) => {
          const deviceArray = text["device" + deviceId];
          const arrayIndex = index % deviceArray.length;
          const iteration = Math.floor(index / deviceArray.length);
          return this.arrayShuffleService.shuffle(deviceArray, iteration)[arrayIndex];
        })
      );
  }
}
